import React from "react"
import { getIn } from "formik"
import StyledInput from "./StyledInput"

function FormikInput({ field, form, ...props }) {
  const { touched, errors } = form
  const error = getIn(touched, field.name) && getIn(errors, field.name)

  return (
    <StyledInput
      error={error}
      name={field.name}
      value={field.value}
      onChange={field.onChange}
      onBlur={field.onBlur}
      {...props}
    />
  )
}

export default FormikInput
