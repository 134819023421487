import styled from "styled-components"

const StyledInput = styled.input`
  padding: 16px 24px;
  border: 2px solid transparent;
  border-color: ${props => (props.error ? "var(--error)" : "transparent")};
  border-radius: 8px;
  box-shadow: inset -3px -3px 5px 0 #ffffff,
    inset 3px 3px 5px 0 rgba(25, 25, 25, 0.15);
  background-color: #ebeaea;
  outline: none;
  width: 100%;
  transition: border-color 250ms ease;
`

export default StyledInput
